// import React, { useState, useEffect } from 'react';
import React, { Suspense } from 'react';
import '../App.sass';
import { useQuery, gql } from '@apollo/client';
import Masonry from 'react-masonry-component'
import { useSelector } from 'react-redux';
import {
   selectDifficulty
} from '../app/features/difficultySlice';
import {
   selectQuery
} from '../app/features/querySlice';
const MasonryPlant = React.lazy(() => import('./masonry_plant'));

const WINDOWED_PLANTS = gql`
query WindowPlants($difficulty: String = "", $name: String = "") {
  listAllPlants(filter: {difficulty: {contains: $difficulty}, searchName: {contains: $name}}) {
    items {
      plantName
      slug
    }
  }
}
`

//load the first six as priority and then the remaining as a seperate bundle
function PlantMasonry() {
  const logo = "https://777377719930-synthesis-assests-s3.s3.us-east-2.amazonaws.com/assets/original-on-transparent.png";
  const query = useSelector(selectQuery);
  const difficulty = useSelector(selectDifficulty);
  console.log(difficulty)
    const { loading, error, data, refetch } = useQuery(WINDOWED_PLANTS, {
      variables: {
        name: query,
        difficulty: difficulty.toLowerCase()
      }
    });
        if (loading || data == undefined) {
            console.log("loading!")
            console.log(data)
            console.log("All Plants: " + error)
            return (
                <div>Loading</div>
            )
        }
        else {
            return (
                    <Masonry className="myshowcase"
                        breakpointCols={3}
                        style={{ left: "30%", position: "aboslute", width: "70%" }}>
                        <div className="mydiv" style={{ left: "30%", position: "absolute", padding: "4px" }}>
                            {data.listAllPlants.items.map(plant => {
                                return (
                                    // <MasonryPlant id={plant.id}></MasonryPlant>
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <MasonryPlant slug={plant.slug}></MasonryPlant>
                                    </Suspense>
                                )
                            })}
                        </div>
                    </Masonry>
            )
        }
}

export default PlantMasonry;