import React from 'react';
import { InputGroup, FormControl, DropdownButton, Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import '../App.sass';
import {
    setDifficulty, selectDifficulty
  } from '../app/features/difficultySlice';
  import {
    setQuery, selectQuery
  } from '../app/features/querySlice';
  import "../sass-styles/one-time-text.css"



function SearchComponent() {
    const difficulty = useSelector(selectDifficulty);
    const dispatch = useDispatch();
    const query = useSelector(selectQuery);
    console.log(query)
    return (
            <>
                <InputGroup style={{ width: "100%", padding: "8px" }}>
                    <FormControl
                        placeholder={query || "Plant name"}
                        aria-label="Plant name"
                        aria-describedby="basic-addon2"
                        style={{ width: "30%" }}
                        value={query}
                        onChange={e => dispatch(setQuery(e.target.value))}
                    />

                    <DropdownButton
                        as={InputGroup.Append}
                        variant="primary"
                        title={ difficulty || "Difficulty"}
                        id="input-group-dropdown-2"
                        drop="down" 
                    >
                        <Dropdown.Item href="#" onSelect={() => dispatch(setDifficulty(""))}>All Plants</Dropdown.Item>
                        <Dropdown.Divider></Dropdown.Divider>
                        <Dropdown.Item href="#" id="easy" onSelect={() => dispatch(setDifficulty("easy"))}>Easy</Dropdown.Item>
                        <Dropdown.Item href="#" id="intermediate" onSelect={() => dispatch(setDifficulty("intermediate"))}>Intermediate</Dropdown.Item>
                        <Dropdown.Item href="#" id="hard" onSelect={() => dispatch(setDifficulty("hard"))}>Hard</Dropdown.Item>
                    </DropdownButton>
                </InputGroup>
            </>
    )
}

export default SearchComponent;