import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import difficultyReducer from './features/difficultySlice';
import queryReducer from './features/querySlice';

export default configureStore({
  reducer: {
    difficulty: difficultyReducer,
    query: queryReducer
  },
});
