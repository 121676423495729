const config = {
    "UserAgent": "aws-amplify-cli/0.1.0",
    "Version": "1.0",
    "IdentityManager": {
        "Default": {}
    },
    "AppSync": {
        "Default": {
            "ApiUrl": "https://nkdhhvxzezddnifrfwluz4ruay.appsync-api.us-east-2.amazonaws.com/graphql",
            "Region": "us-east-2",
            "AuthMode": "API_KEY",
            "ClientDatabasePrefix": "Plants_API_KEY"
        }
    }
}
export default config;
