import React, { useEffect } from 'react';
import './App.sass';
import Home from './components/Home';
import Plant from './components/Plant';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

//wrap individual containers in cols

function App() {
    useEffect(() => {
        document.title = "Let's grow together"
      }, [])
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route path="/plant/:slug">
                    <Plant />
                </Route>
            </Switch>
        </Router>
    )
}

export default App;
