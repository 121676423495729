import React from 'react';
import '../App.sass';
import { useQuery, gql } from '@apollo/client';
import { BrowserRouter, Link } from 'react-router-dom';
import SearchComponent from './Search'


function HomeTemplate({ children }) {
    const logo = "https://777377719930-synthesis-assests-s3.s3.us-east-2.amazonaws.com/assets/original-on-transparent.png";
    return (
        <div className="row mydiv" style={{ backgroundColor: "#f4f4f4" }}>
            <div className="mycontainer__sidebar">
                <div className="mysidebar">
                    <h6 className="mysidebar__title">
                        <img src={logo} alt="Synthesis" />
                    </h6>
                    <ul className="mysidebar__menu">
                        <li>
                            <SearchComponent></SearchComponent>
                        </li>
                    </ul>
                </div>
            </div>
            {children}
        </div>
    )
}

export default HomeTemplate;
