
import React from 'react';
import '../App.sass';
import PlantMasonry from './PlantMasonry';
import HomeTemplate from './HomeTemplate';
import { Helmet } from "react-helmet";

function Home() {
    return (
        <div>
            <Helmet>
                <style>{'body { background-color: #f4f4f4; }'}
                </style>
            </Helmet>
            <HomeTemplate>
                <PlantMasonry />
            </HomeTemplate>
        </div>
    )
}

export default Home;