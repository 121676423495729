import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTemperatureHigh } from '@fortawesome/free-solid-svg-icons'

import "../sass-styles/one-time-text.css"

export const PLANT_QUERY = gql`
query PlantDetailQuery($slug: String!) {
  listAllPlants(filter: {slug: {eq: $slug}}) {
    items {
      	plantName
        difficulty
        plantDescription
        s3ImageUrl
        lightCare
        waterCare
        s3ImageUrl
        temperature
        fertilization
        propogation
        waterImage
        placementImage
        fertilizationImage
    }
  }
}

`


function Plant() {
    let { slug } = useParams();
    const logo = "https://777377719930-synthesis-assests-s3.s3.us-east-2.amazonaws.com/assets/original-on-transparent.png";
    const { loading, error, data } = useQuery(PLANT_QUERY, {
        variables: { slug: slug }
    });
    if (loading || data == undefined) {
        console.log(error)
        return (
            <div>Loading......</div>
        )
    }
    else {
        const plant = data.listAllPlants.items[0]
        document.title = plant.plantName
        return (
            <div className="col mydiv">
                <div className="myheader-container">
                    <h6>
                        <Link to="/"><img src={logo} height="60px" alt="Synthesis" /></Link>
                    </h6>
                </div>
                <article className="mysheet" style={{ backgroundColor: "#f4f4f4" }}>
                    <div className="mycontainer">
                        <div className="col mydiv" style={{ width: "50%" }}>
                            <div className="mysheet__inner">
                                <h1 className="mysheet__title" style={{ "color": "#4575f7", textAlign: "center" }}>{plant.plantName}</h1>
                                <div className="container" style={{ display: 'inline-flex', justifyContent: "space-between", paddingLeft: 0, paddingRight: 0, paddingBottom: "10%" }}>
                                    <div style={{ fontSize: "1.5em" }}>
                                        Difficulty to grow: <p id={plant.difficulty} style={{ fontSize: "1em" }}>{(plant.difficulty).charAt(0).toUpperCase() + (plant.difficulty).slice(1)}</p>
                                    </div>
                                    <div style={{ fontSize: "1.5em" }}>
                                        Temperature Range: <p id="default" style={{ fontSize: "1em" }}>{plant.temperature}</p>
                                    </div>
                                </div>
                                <div className="mysheet__body">
                                    <p className="mysheet__lead" style={{ color: "green", fontSize: "1.5em" }}>About this plant:</p>
                                    <p dangerouslySetInnerHTML={{ __html: plant.plantDescription }} style={{ color: "#444", fontSize: "1.25em" }}></p>
                                </div>
                            </div>
                        </div>

                        <div className="col mydiv" style={{ width: "50%" }}>
                            <div className="mysheet__gallery">
                                <img src={plant.s3ImageUrl}></img>
                            </div>
                        </div>
                    </div>
                </article>
                <article className="mysheet" style={{ backgroundColor: "#f4f4f4" }}>
                    <div className="mysheet" style={{ backgroundColor: "#f4f4f4" }}>
                    <div style={{ width: "100%" }}>
                        <div className="row mydiv" style={{ width: "100%", justifyContent: "space-between" }}>
                            <div className="mysheet__inner" style={{ width: "50%", textAlign: "center" }}>
                                <img src={plant.waterImage}/>
                            </div>
                            <div className="mysheet__inner" style={{ width: "50%" }}>
                                <p className="mysheet__lead" style={{ color: "green", textAlign: "center", fontSize: "1.5em" }}>How to Water this Plant:</p>
                                <p style={{fontSize: "1.25em"}}>{plant.waterCare}</p>
                            </div>
                        </div>
                        <div className="row mydiv" style={{ width: "100%", justifyContent: "space-between" }}>
                            <div className="mysheet__inner" style={{ width: "50%", textAlign: "center" }}>
                                <p className="mysheet__lead" style={{ color: "green", fontSize: "1.5em" }}>Where to Place this Plant:</p>
                                <p style={{fontSize: "1.25em"}}>{plant.lightCare}</p>
                            </div>
                            <div className="mysheet__inner" style={{ width: "50%", textAlign: "center" }}>
                                <img src={plant.placementImage}/>
                            </div>
                        </div>
                        <div className="row mydiv" style={{ width: "100%", justifyContent: "space-between", paddingTop: "4%" }}>
                        <div className="mysheet__inner" style={{ width: "50%", textAlign: "center" }}>
                                <img src={plant.fertilizationImage}/>
                            </div>
                            <div className="mysheet__inner" style={{ width: "50%" }}>
                                <p className="mysheet__lead" style={{ color: "green", textAlign: "center", fontSize: "1.5em" }}>Fertilization:</p>
                                <p style={{fontSize: "1.25em"}}>{plant.fertilization}</p>
                            </div>
                            {/* <div className="mysheet__inner" style={{ width: "50%", textAlign: "center" }}>
                                <p className="mysheet__lead" style={{ color: "green", fontSize: "1.5em" }}>How to propogate:</p>
                                <p style={{fontSize: "1.25em"}}>{plant.propogation}</p>
                            </div> */}
                        </div>
                    </div>
                    </div>
                </article>
            </div>
        )
    }
}

export default Plant;